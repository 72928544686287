<template>
  <div class="student">
    <div class="condition_div">
      <el-form :inline="true" class="flex" :model="searchForm">
        <el-form-item>
          <el-button type="primary" size="small" @click="add('')">新增</el-button>
        </el-form-item>
        <div>
          <el-form-item>
            <el-input v-model="searchForm.typeName" placeholder="请输入类型名称" suffix-icon="el-icon-search" clearable>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getList(true)" size="small">查询</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
      <el-table-column type="index" label="序号" width="60" align="center" fixed>
        <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
      </el-table-column>
      <el-table-column prop="typeName" label="类型名称" min-width="120" align="center"></el-table-column>
      <el-table-column prop="adaptorType" label="所属分类" min-width="120" align="center">
        <template slot-scope="scope">
          <div>{{scope.row.adaptorType==3?"学生":scope.row.adaptorType==4?"家长":""}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" min-width="120" align="center"></el-table-column>
      <el-table-column prop="updateTime" label="最后操作时间" min-width="200" align="center"></el-table-column>
      <el-table-column prop="updateBy" label="操作人" min-width="120" align="center"></el-table-column>
      <el-table-column prop="status" label="当前状态" min-width="100" align="center">
        <template slot-scope="scope">
          <span class="dot" :class="stateClass(scope.row.status)"></span>
          {{ showState(scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="130" align="center">
        <template slot-scope="scope">
          <el-button class="ge" type="text" size="small" @click="release(scope.row.typeId,scope.row.status)">
            {{ scope.row.status==0?"禁用":"启用" }} </el-button>
          <span class="ge">|</span>
          <el-button type="text" size="small" @click="add(scope.row)"> 编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"></pagination>
    <!-- 新增\编辑 -->
    <modal :obj="{
        title: edit ? '编辑' : '新增',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="类型名称：" prop="typeName">
          <el-input v-model="addForm.typeName" placeholder="请输入类型名称"></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-select v-model="addForm.status" placeholder="请选择状态" class="w_100">
            <el-option v-for="(item, index) in stateList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model="addForm.remark" :rows="4" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "../../../components/pagination";
  import modal from "../../../components/modal";
  import {
    psyTypeList,
    typeStatus,
    typeSave,
    typeUpdate,
    typeInfo,
  } from "../../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        tableHeight: this.$util.getHeight(400),
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0,
        searchForm: {
          status: "",
          typeName: "",
          adaptorType: 3, //3学生4家长
          typeTheme: 1,
        },
        tableData: [],
        stateList: [{
          value: 0,
          label: "启用",
        }, {
          value: 1,
          label: "禁用",
        }],
        showBtn: false,
        addDialog: false,
        edit: "",
        addForm: {
          typeSort: "",
          typeId: "",
          status: "",
          typeName: "",
          adaptorType: 3, //3学生4家长
          typeTheme: 1,
        },
        updateman: "",
        rules: {
          namee: [{
            required: true,
            message: "请输入类型名称"
          }],
          flag: [{
            required: true,
            message: "请选择状态"
          }],
          themeId: [{
            required: true,
            message: "请选择所属分类"
          }],
        },
      };
    },
    computed: {
      userInof() {
        return this.$store.state.userInof;
      },
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    created() {
      this.getList();
    },
    mounted() {
      window.addEventListener('resize', () => {
        let num = this.showBtn ? 350 : 400
        this.getTableHeight(num)
      })
    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },
      //科普类型列表
      getList(e) {
        this.getTableHeight(360)
        this.$request(psyTypeList(this.current, this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },

      //启用禁用-弹窗
      release(typeId, status) {
        let text = status == 1 ? "确认启用?" : "确认禁用?";
        let val = {
          typeId: typeId,
          status: status,
        };
        this.$util.isSwal(text, this.enableDisable, val);
      },
      enableDisable(val) {
        let status = val.status == 0 ? 1 : 0;
        this.$request(typeStatus, {
          typeId: val.typeId,
          status: status
        }).then((res) => {
          if (res.code == 0) {
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },

      //   编辑排序
      editSort() {
        let ids = [];
        let order = [];
        this.tableData.find((item) => {
          ids.push(item.id);
          order.push(item.orderno);
        });
        this.$request(themetypeBatchOrder, {
          ids,
          order
        }).then((res) => {
          if (res.code === "200") {
            this.showBtn = false;
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   新增\编辑-弹框
      add(val) {
        this.edit = val;
        this.addDialog = true;
        this.addForm = {
          typeSort: "",
          typeId: "",
          status: "",
          typeName: "",
          adaptorType: 3, //3学生4家长
          typeTheme: 1,
        };
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        if (this.edit) {
          this.getThemetypeInfo(val);
        } else {}
      },
      //   新增编辑-上传接口
      addSubmit() {
        let url = this.edit ? typeUpdate : typeSave;
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code == 0) {
                this.addDialog = false;
                this.$util.message("suc", "操作成功");
                this.getList();
              }
            });
          }
        });
      },
      // 单个详情
      getThemetypeInfo(val) {
        this.$request(typeInfo, {
          typeId: val.typeId
        }).then((res) => {
          if (res.code == 0) {
            this.addForm = res.data;
          }
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
      showState(val) {
        switch (val) {
          case 0:
            return "启用";
          case 1:
            return "禁用";
        }
      },
      stateClass(val) {
        switch (val) {
          case 0:
            return "green_dot";
          case 1:
            return "grey_dot";

        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .student {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }
  }
</style>